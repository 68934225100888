import { useMemo } from "react";

import _ from "lodash";

import { EnvUtils } from "@/utils/EnvUtils";
import { usePrismaObjectSync } from "lib/frontend/cm/store/objectPool/prismaObjectSync";
import { IntegrationProvider } from "prisma/cm/client";

import { useSafeCrossAppUser } from "./useSafeCrossAppUser";

export function useReturnToFifteenFiveUrl() {
  const user = useSafeCrossAppUser();

  const integrations = usePrismaObjectSync.integration.findMany();
  const subdomain = useMemo(() => {
    const fifteenFiveIntegration = _.find(integrations, {
      provider: IntegrationProvider.FIFTEEN_FIVE,
    });
    if (!fifteenFiveIntegration) return "";

    const settings = fifteenFiveIntegration.settings as Record<string, string>;

    return settings["companySubdomain"];
  }, [integrations]);

  if (!user?.company?.isFifteenFivePartner) {
    return null;
  }

  if (EnvUtils.isProd) {
    return subdomain ? `https://${subdomain}.15five.com` : "https://my.15five.com";
  } else {
    return subdomain ? `https://${subdomain}.staging.15five.com` : "https://my.staging.15five.com";
  }
}
